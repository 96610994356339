<template>
  <div class="nav_search" v-show="this.$store.state.resour">
    <div class="container-second">

      <img class="img" src="../../assets/j.png" />
      <span class="inde" @click="index">资源交易</span>

      <div id="nav2">
        <el-menu
          :default-active="this.$route.path"
          class="el-menu-demo "
          mode="horizontal"
          :router="true"
          v-if="show_for"
        >
          <el-menu-item class="title-xjw" index="/resources_index"
            >资源首页</el-menu-item
          >
          <el-menu-item class="title-xjw ad" index="/Resource_transactions"
            >资源交易</el-menu-item
          >
        </el-menu>
        <el-menu
          :default-active="this.$route.path"
          class="el-menu-demo"
          mode="horizontal"
          :router="true"
          v-if="show"
        >
          <el-menu-item class="title-xjw" index="/resources_index"
            >资源首页</el-menu-item
          >
          <el-menu-item class="title-xjw " index="/Resource_transactions"
            >资源交易</el-menu-item
          >
          <el-menu-item
            class="title-xjw"
            :style="{ color: color }"
            index="/myresourxce"
            >我的资源</el-menu-item
          >
        </el-menu>
      </div>

      <div class="input">
        <input
          type="text"
          style="font-size:15px"
          v-model="input2"
          @keyup.enter="Search"
          placeholder="请输入内容"
        />
        <span class="search-icon" @click="Search"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  props: {
    msg: String,
  },
  data() {
    return {
      input2: "",
      info: true,

      show: false,
      show_for: true,
      color: "",
    };
  },
  created() {
    if (this.$store.state.userinfo.name != "") {
      this.show = true;
      this.show_for = false;
    } else {
      this.show = false;
      this.show_for = true;
    }
  },
  mounted() {
    // 未登录 导航栏居中
    // if (this.$store.state.userinfo.id === undefined) {
    //   // 未登录 .el-menu-item {margin-left: 200px;}
    //   let e_li = document.getElementsByClassName("el-menu-item title-xjw");
    //   for (let i = 0; i < e_li.length; i++) {
    //     e_li[i].style.marginLeft = "2.26471rem";
    //   }
    // } else {
    //   let e_li = document.getElementsByClassName("el-menu-item title-xjw");
    //   for (let i = 0; i < e_li.length; i++) {
    //     e_li[i].style.marginLeft = "1.69118rem";
    //   }
    // }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path != from.path) {
        if (to.path === "/mydownload") {
          this.color = "#42b983";
          return;
        }
        if (to.path === "/resources_index") {
          this.color = "";
          return;
        }
        if (to.path === "/Resource_transactions") {
          this.color = "";
          return;
        }
      }
    },
  },
  methods: {
    Search() {
      this.$router.push({ name: "Research", params: { input: this.input2 } });
    },
    Release() {
      this.$router.push({ name: "Release", params: { userId: 123 } });
    },
    index() {
      this.$router.push("/resources_index");
    },
  },
};
</script>

<style scoped>
.nav_search {
  padding: 0.147059rem 0;
  border-bottom: 0.014706rem solid rgba(206, 213, 215, 1);
  background: rgba(255, 255, 255, 1);
  height: 0.330882rem;
  line-height: 0.330882rem;
  border-bottom: 0.014706rem solid rgba(206, 213, 215, 1);
  background: rgba(255, 255, 255, 1);
}
.container-second {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.nav_search .img {
  height: 38.16px;
  /* margin-left: -92px;
  vertical-align: -7px; */
}
.nav_search .inde {
  cursor: pointer;
  font-size: 0.176471rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(0, 132, 255, 1);
  margin-left: 0.102941rem;
  font-weight: bold;
}
.nav_search .el-input {
  width: 300px;
  margin-left: 102px;
}
.fb_lll {
  background-color: #0e86f6 !important;
  color: #ffffff !important;
  margin-left: 30px !important;
  padding: 6px 20px !important;
}
.nav_search >>> .el-input__inner {
  height: 40px;
  font-size: 12px;
}
.nav_search >>> .el-icon-search:before {
  font-size: 12px;
}


.nav_search .input {
  width: 315px;
  height: 34px;
  line-height: 34px;
  background: rgba(238, 238, 238, 1);
  border: 1px solid rgba(181, 193, 197, 1);
  border-radius: 17px;
  text-align: left;
  vertical-align: middle;
}
.nav_search .input input {
  border: none;
  width: 250px;
  background: rgba(238, 238, 238, 1);
  margin-left: 17px;
  vertical-align: middle;
  outline: none;
}
.nav_search .input .search-icon {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/fdj2.png");
  background-size: 24px 24px;
  vertical-align: middle;
}
.title-xjw{
  font-size: 18px;
}
#nav2 >>> ul {
  width: 400px;
  display: flex;
  justify-content: space-between;
}
#nav2 >>> .el-menu-item {
  padding:0;
}
#nav2 >>> .el-menu-demo{
  border-width: 0;
}
#nav2 >>> ul li {
  height: 60px;
  line-height: 60px;
}
#nav2 >>> ul li:hover{
  background-color:transparent !important;
}
#nav2 >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: #f7f7f7;
}
#nav2 .router-link-exact-active.is-active {
  color: #42b983;
}
</style>
