<template>
  <div id="nav-copy">
    <resour />
    <!-- <div id="nav2">
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo "
        mode="horizontal"
        :router="true"
        v-if="show_for"
      >
        <el-menu-item class="title-xjw" index="/resources_index"
          >资源首页</el-menu-item
        >
        <el-menu-item class="title-xjw ad" index="/Resource_transactions"
          >资源交易</el-menu-item
        >
      </el-menu>
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        :router="true"
        v-if="show"
      >
        <el-menu-item class="title-xjw" index="/resources_index"
          >资源首页</el-menu-item
        >
        <el-menu-item class="title-xjw " index="/Resource_transactions"
          >资源交易</el-menu-item
        >
        <el-menu-item
          class="title-xjw"
          :style="{ color: color }"
          index="/myresourxce"
          >我的资源</el-menu-item
        >
      </el-menu>
    </div> -->
    <!-- 修改后位置 -->
    <div class="center">
      <router-view />
    </div>
  </div>
</template>

<script>
import resour from "@/components/resour_search";
export default {
  name: "index.vue",
  components: {
    resour,
  },
  data() {
    return {
      // show: false,
      // show_for: true,
      // color: "",
    };
  },
  created() {
    // console.log(this.$store.state.userinfo.name, "111");
    // if (this.$store.state.userinfo.name != "") {
    //   this.show = true;
    //   this.show_for = false;
    // } else {
    //   this.show = false;
    //   this.show_for = true;
    // }
  },
  watch: {
    // $route(to, from) {
    //   //监听路由是否变化
    //   if (to.path != from.path) {
    //     if (to.path === "/mydownload") {
    //       this.color = "#42b983";
    //       return;
    //     }
    //     if (to.path === "/resources_index") {
    //       this.color = "";
    //       return;
    //     }
    //     if (to.path === "/Resource_transactions") {
    //       this.color = "";
    //       return;
    //     }
    //   }
    // },
  },
  mounted() {
    // 未登录 导航栏居中
    // console.log("lal", this.$store.state.userinfo.id);
    // if (this.$store.state.userinfo.id === undefined) {
    //   // 未登录 .el-menu-item {margin-left: 200px;}
    //   let e_li = document.getElementsByClassName("el-menu-item title-xjw");
    //   console.log(e_li);
    //   for (let i = 0; i < e_li.length; i++) {
    //     e_li[i].style.marginLeft = "2.26471rem";
    //   }
    // } else {
    //   let e_li = document.getElementsByClassName("el-menu-item title-xjw");
    //   console.log(e_li);
    //   for (let i = 0; i < e_li.length; i++) {
    //     e_li[i].style.marginLeft = "1.69118rem";
    //   }
    // }
  },
  methods: {},
};
</script>

<style scoped>
.el-menu-demo {
  background-color: #f7f7f7;
  border-bottom: none;
}
.cg {
  margin-left: 321px !important;
}
.el-menu-item {
  margin-left: 180px !important;
  border-bottom: none;
}
.center {
  /* width: 80%; */
  margin: 0 auto; /* 水平居中 */
}
.div-inside {
  /* width: 80%; */
  margin: 0 auto;
}
.title-xjw {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3d3d3d;
}
#nav2 {
  text-align: center;
  height: 0.661765rem;
  line-height: 0.661765rem;
  background: #f7f7f7;
}
#nav2 >>> ul {
  width: 80%;
  margin: 0 auto;
  /* top: 14px; */
  height: 60px;
  line-height: 60px;
  transform: translate(10px, 12px);
}
#nav2 >>> ul li {
  height: 60px;
  line-height: 60px;
}
#nav2 >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: #f7f7f7;
}
#nav2 .router-link-exact-active.is-active {
  color: #42b983;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #42b983;
  border: none;
  background: #f7f7f7;
}
</style>
